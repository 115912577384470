<template>
    <div v-if="loading" class="card">
        <LoadingSpinner class="my-4" />
    </div>
    <LoadingError v-else-if="error" class="vh-50" />
    <div v-else>
        <!-- Route-specific hack to hide this card. If more routes need this, change to route metadata. -->
        <div class="card mb-3" v-if="$route.name !== 'edit-customer'">
            <div class="card-header btn-reveal-trigger d-flex justify-content-between align-items-start">
                <div>
                    <h6 class="text-uppercase text-600"><span class="fas fa-users mr-1" />Customer</h6>
                    <div class="mb-2">
                        <h5 class="d-inline">{{ customer.name }}</h5>
                        <span class="badge badge-soft-secondary align-text-top ml-1">{{ customer.identifier }}</span>
                    </div>
                </div>
                <router-link :to="{ name: 'edit-customer' }"
                             v-if="$auth.hasPermissions('update:customers')"
                             class="btn btn-sm btn-reveal text-600"
                             aria-label="Edit customer">
                    <span class="fas fa-pencil-alt" />
                </router-link>
            </div>
            <div class="card-body bg-light border-top">
                <CustomerAttributes :customer="customer" />
            </div>
        </div>
        <router-view :customer="customer"
                     @customer-edited="newCustomer => customer = newCustomer" />
    </div>
</template>

<script>
    import CustomerAttributes from '../components/CustomerDetails/CustomerAttributes';
    import { getCustomerById } from '../services/CustomerService';
    import LoadingError from '../components/LoadingError';
    import LoadingSpinner from '../components/LoadingSpinner';

    export default {
        name: 'CustomerDetails',
        components: {
            LoadingSpinner,
            LoadingError,
            CustomerAttributes,
        },
        data() {
            return {
                loading: true,
                error: null,
                customer: null,
            };
        },
        async mounted() {
            try {
                const { data } = await getCustomerById(this.$route.params.id);
                data.users.forEach(user => {
                    user.full_name = `${user.first_name} ${user.last_name}`;
                });
                this.customer = data;
            } catch (err) {
                this.error = err;
            } finally {
                this.loading = false;
            }
        },
    };
</script>
