<template>
    <div class="row">
        <div class="col-lg col-xxl-5">
            <div class="row">
                <div class="col-5 col-sm-4">
                    <p class="font-weight-semi-bold mb-1">Email</p>
                </div>
                <div class="col">
                    <span v-if="customer.email">{{ customer.email }}</span>
                    <span v-else class="text-400">N/A</span>
                </div>
            </div>
            <div class="row">
                <div class="col-5 col-sm-4">
                    <p class="font-weight-semi-bold mb-1">Phone</p>
                </div>
                <div class="col">
                    <span v-if="customer.phone">{{ customer.phone }}</span>
                    <span v-else class="text-400">N/A</span>
                </div>
            </div>
        </div>
        <div class="col-lg col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
            <div class="row">
                <div class="col-5 col-sm-4">
                    <p class="font-weight-semi-bold mb-1">Website</p>
                </div>
                <div class="col">
                    <span v-if="customer.website">{{ customer.website }}</span>
                    <span v-else class="text-400">N/A</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CustomerAttributes',
        props: {
            customer: {
                type: Object,
                required: true,
            },
        },
    };
</script>
